<script>
    import Layout from "../../layouts/main";
    import PageHeader from "@/components/page-header";
    import appConfig from "@/app.config";
    import General from '@/services/General';
    import vue2Dropzone from "vue2-dropzone";
    import "vue2-dropzone/dist/vue2Dropzone.min.css";
    import { FormWizard, TabContent } from "vue-form-wizard";
    import "vue-form-wizard/dist/vue-form-wizard.min.css";
    import flatPickr from "vue-flatpickr-component";
    import "flatpickr/dist/flatpickr.css";
    import ConfirmDatePlugin from 'flatpickr/dist/plugins/confirmDate/confirmDate.js';
    import 'flatpickr/dist/plugins/confirmDate/confirmDate.css';
    import moment from "moment";

    export default {

      // eslint-disable-next-line vue/no-unused-components
        components: { Layout, PageHeader,  vueDropzone: vue2Dropzone,  FormWizard,
          // eslint-disable-next-line vue/no-unused-components
          TabContent, flatPickr},
        page: {
            title: "Application Params",
            meta: [
                {
                    name: "description",
                    content: appConfig.description,
                },
            ],
        },
        data() {
            return {
                title: "Application Params",
                items: [
                    {
                        text: "Application",
                        href: "/app",
                    },
                    {
                        text: "Application Params",
                        active: true,
                    },
                ],
                showLoader:false,
                error: null,
                showModal: false,
                modalData: [],
                link_id: null,
                results: {},
                form: {
                    csrf_token: localStorage.getItem('csrf_token'),
                    selectedKey: "",
                    selectedValue: "",
                },
                show_add_image:false,
                dropzoneOptions_primary: {
                    url: '/',
                    autoProcessQueue: false,
                    addRemoveLinks: true,
                    thumbnailWidth: 150,
                    thumbnailMethod: 'contain',
                    maxFilesize: 5,
                    acceptedFiles:'image/*',
                    maxFiles: 1,
                },
              datetimeconfig: {
                enableTime: true,
                time_24hr: true,
                dateFormat: "d-m-Y H:i",
                minuteIncrement: 1,
                defaultDate: new Date(),
                plugins: [new ConfirmDatePlugin({})]
              },
            };
        },
        mounted() {
            this.getAppParamsKeys();
        },
        methods: {

            async deleteImage(params) {
                if (params.input_type === 'img' && params.value !== '') {

                    const key = params.key;
                    const emptyValue = '';

                    params.value = emptyValue;

                    const data = { key: params.key };

                    await General.deleteAppParamsImage(data).then((response) => {
                        this.successmsg('Application Param Image Deleted!');
                    }).catch(error => {
                        this.failedmsg('Failed to Delete Application Params Image.');
                    }).finally(() => {
                        this.getAppParamsKeys();
                    })

                } else  {
                    this.failedmsg('Failed to Update Application Params.');
                }

            },





            async submitForm() {
                try {
                        let formData = new FormData();
                         this.modalData.forEach((element) => {
                           if (element.input_type === 'utcTimestamp' && element.value) {
                             element.value = moment(element.value, 'DD-MM-YYYY HH:mm').unix();
                           }
                            formData.append(element.key, element.value)
                         });

                    await General.updateAppParams(formData).then((response) => {
                         this.successmsg('Application Params Updated!');
                    }).catch(error => {
                        this.failedmsg('Failed to Update Application Params.');
                    }).finally(() => {
                        //this.refreshData()
                        this.tryingToEdit = false;
                        this.getAppParamsKeys();
                        this.show_add_image = false;
                    })
                } catch (error) {
                    this.failedmsg('Failed to Update Application Params.');
                }

            },

            async getAppParamsKeys() {
                try {
                    this.showLoader = true
                    this.toggleBusy();
                    const response = await General.getAppParamsKeys();
                    const response1 = await General.getAppParams();
                    this.modalData = response.data.app_params_keys;

                    this.modalData.forEach(param => {
                        const appParam = response1.data.app_params.find(app => app.key === param.key);
                        if (appParam) {
                            param.value = appParam.value;
                          if (param.input_type === 'utcTimestamp' && param.value) {
                            param.value = moment.unix(param.value).format('DD-MM-YYYY HH:mm');
                          }
                        }
                    });
                    this.toggleBusy();

                        this.showLoader = false;

                }
                catch (error) {
                    this.toggleBusy();
                }
            },

        selectFileSmall(files){
             setTimeout(() => {

                    this.$refs.myVueDropzone_primary.forEach((dropZone) => {
                        var row_number = dropZone.$attrs.row_index;
                        let queuedFiles = dropZone.getQueuedFiles();

                         if (queuedFiles.length > 1) {
                            this.failedmsg('You can only have one file')

                            files.forEach((value) => {
                                dropZone.removeFile(value)
                            });
                        }

                          if (typeof queuedFiles[0] !== 'undefined') {
                               this.modalData[row_number].value = queuedFiles[0]
                          }
                    });

             }, 250);
        },

         viewImage(index) {
            var id_view = 'view_btn_'+index
            var id_change = 'change_btn_'+index

            document.getElementById(id_view).style.display = 'none';
            document.getElementById(id_change).style.display = 'inline-block';

            var show_image = document.getElementById('show_image_'+index);
            var name = document.getElementById('name_'+index);

            if (show_image) {
               document.getElementById('show_image_'+index).style.display = 'inline-block';
            }

            if (name) {
             document.getElementById('name_'+index).style.display = 'none';
            }

        },
        changeImage(index) {
            var id_view = 'view_btn_'+index
            var id_change = 'change_btn_'+index

            document.getElementById(id_view).style.display = 'inline-block';
            document.getElementById(id_change).style.display = 'none';

            var show_image = document.getElementById('show_image_'+index);
            var name = document.getElementById('name_'+index);

           if (show_image) {
                document.getElementById('show_image_'+index).style.display = 'none';
            }

           if (name) {
                document.getElementById('name_'+index).style.display = 'inline-block';
           }

        },


        },
        middleware: "authentication",
    };
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <div class="row mt-4">
                            <div class="col-12" v-if="!showLoader">
                            <div class="col-sm-12 col-md-12 mb-5">
                                <div id="tickets-table_length" class="dataTables_length">
                                    <form @submit.prevent="submitForm">
                                        <div class="row">
                                            <div class="col-2"><label  style="font-size: 20px;">Key </label></div>
                                            <div class="col-3"><label  style="font-size: 20px;">Name </label></div>
                                            <div class="col-1"><label  style="font-size: 20px;">Type </label></div>
                                            <div class="col-2"><label  style="font-size: 20px;">Input Type </label></div>
                                            <div class="col-4"><label  style="font-size: 20px;">Value </label></div>
                                        </div>
                                        <div v-for="(params, index) in modalData" :key="index">

                                            <div class="row">
                                                <div class="col-2">
                                                    <div class="form-group">
                                                        <div class="form-group">
                                                            <span :id="'key_' + index" >{{ params.key }}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-3">
                                                    <div class="form-group">
                                                        <div class="form-group">
                                                            <span>{{ params.name }}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-1">
                                                    <div class="form-group">
                                                        <div class="form-group">
                                                            <span :id="'type_' + index" >{{ params.type }}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-2">
                                                    <div class="form-group">
                                                        <div class="form-group">
                                                            <span :id="'input_type_' + index" >{{ params.input_type }}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div v-if="params.input_type === 'string' || params.input_type === 'url' " class="col-4">
                                                    <div class="form-group">
                                                        <input :id="'name_' + index" v-model="params.value" class="form-control" :name="'name_' + index" />
                                                    </div>
                                                </div>
                                              <div v-else-if="params.input_type === 'boolean'" class="col-4 form-check form-switch form-switch-lg">
                                                <input v-model="params.value"
                                                       type="checkbox"
                                                       class="form-check-input"
                                                       id="switch1"
                                                       true-value="1"
                                                       false-value="0"
                                                       :style="{ left: '0px' }"
                                                />
                                              </div>
                                              <div v-else-if="params.input_type !== 'img' && params.input_type !== 'utcTimestamp'" class="col-4">
                                                <div class="form-group">
                                                  <input :id="'name_' + index" v-model="params.value" class="form-control" :name="'name_' + index" />
                                                </div>
                                              </div>
                                              <div v-else-if="params.input_type === 'utcTimestamp'" class="col-4">
                                                <div class="form-group">
                                                  <flat-pickr
                                                      :id="'name_' + index"
                                                      v-model="params.value"
                                                      :config="datetimeconfig"
                                                      class="form-control"
                                                      :name="'name_' + index"
                                                  />
                                                </div>
                                              </div>
                                                <div v-else class="col-4">
                                                    <div class="form-group">

                                                          <template>
                                                             <b-form-group :id="'show_image_' + index" v-if="params.value != ''" label-for="image" class="mb-3  image-area" style="display:inline_block">
                                                                 <img  :src="params.value" alt=""  class="img-thumbnail" >
                                                             </b-form-group>

                                                         </template>
                                                         <template>

                                                            <vue-dropzone
                                                                    style="display:none"
                                                                    :id="'name_' + index"
                                                                    :name="'name_' + index"
                                                                    ref="myVueDropzone_primary"
                                                                    :use-custom-slot="true"
                                                                    :options="dropzoneOptions_primary"
                                                                    @vdropzone-files-added="selectFileSmall"
                                                                    no-progress-bar
                                                                    :row_index=index
                                                                    v-model="params.value"
                                                            >
                                                                <div class="dropzone-custom-content">
                                                                    <i class="display-4 text-muted bx bxs-cloud-upload"></i>
                                                                    <h4>Drop file here or click to upload.</h4>
                                                                </div>
                                                            </vue-dropzone>
                                                         </template>
<div class="row">
                                                         <!--<a  href="javascript:void(0);" class="btn btn-primary btn-sm" @click="show_add_image = !show_add_image">{{show_add_image ? 'View Image' : 'Change Image'}}</a>-->
                                                         <div class="col-sm-4">
                                                             <a :id="'view_btn_' + index" href="javascript:void(0);" class="btn btn-primary btn-sm mb-2" @click="viewImage(index)" style="display:none;">View Image</a>
                                                             <a :id="'change_btn_' + index"  href="javascript:void(0);" class="btn btn-primary btn-sm mb-2" @click="changeImage(index)" >Change Image</a>
                                                         </div>
                                                        <div class="col-sm-4">
                                                            <!-- Display the delete button only if there is a photo -->
                                                            <!--<a :id="'delete_btn_' + index" href="javascript:void(0);" class="btn btn-danger btn-sm mb-2" @click="deleteImage(index)" v-if="params.input_type == 'img' && params.value != ''">Delete Image</a>-->
                                                            <a :id="'delete_btn_' + index" href="javascript:void(0);" class="btn btn-danger btn-sm mb-2" @click="deleteImage(params)" v-if="params.input_type == 'img' && params.value != ''">Delete Image</a>

                                                        </div>

</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <input type="hidden" name="csrf_token" v-model="form.csrf_token" />
                                        <button style="margin-top: 15px" type="submit" class="btn btn-primary">Update Application Params</button>
                                    </form>
                                </div>
                            </div>
                            </div>
                            <div class="card-body d-flex align-items-center justify-content-center" v-else>
                                <b-spinner large></b-spinner>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>